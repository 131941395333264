import { useEffect, useState } from 'react';
import logo from '../assets/img/logo.svg';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SignUp from './SignUp';
import SignIn from './SignIn';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';

function Navbar() {
  const [openModal, setOpenModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const [token] = useLocalStorage('token', '');

  useEffect(() => {
    const email = window.localStorage.getItem('username');
    setUser(email);
  }, [token, navigate]);

  const logout = (e) => {
    e.preventDefault();
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('username');
    setUser('');
    navigate('/');
  };

  return (
    <>
      <header className="bg-light py-3">
        <div className="container flex-center flex-space-between">
          <div className="flex-center">
            <Link to="/" className="flex-center logo">
              <img src={logo} alt="IIMPRO-CC" style={{ width: 55 }} />
              <span className="ms-3">IIMPRO-CC</span>
            </Link>
            {user && (
              <Dropdown>
                <Dropdown.Toggle variant="default">{user}</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link className="my-2" to="/profile">
                    Profil
                  </Link>

                  <div className="my-2" onClick={logout}>
                    Keluar
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>

          {!user && (
            <div>
              <Button
                type="button"
                size="sm"
                className="btn btn-secondary me-2"
                onClick={() => setOpenSignInModal(true)}
              >
                Login
              </Button>
              <Button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalId"
                size="sm"
                className="ms-2"
                onClick={() => setOpenModal(true)}
              >
                Pendaftaran akun
              </Button>
            </div>
          )}
        </div>
      </header>

      <SignUp open={openModal} setOpen={setOpenModal} />
      <SignIn open={openSignInModal} setOpen={setOpenSignInModal} />
    </>
  );
}

export default Navbar;
