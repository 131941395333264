import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import SignUp from './SignUp';

function Main() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <section className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="blue-block">
                <h1 className="mb-5">
                  Tim kami lebih dari sekadar ahli. Mereka adalah orang-orang
                  yang mengetahui dan memahami kebutuhan klien kami
                </h1>
                <p className="mb-5">
                  Kami menawarkan kepada klien kami solusi terbaik dan teknologi
                  terbaik yang memungkinkan mereka untuk menjadi yang terbaik
                  dalam bisnis mereka. Dan kami tidak hanya menawarkan, kami
                  menciptakan solusi ini bersama dengan klien kami.
                </p>

                <Button
                  className="px-5 btn-secondary"
                  onClick={() => setOpenModal(true)}
                >
                  Registrasi
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SignUp open={openModal} setOpen={setOpenModal} />;
    </>
  );
}

export default Main;
