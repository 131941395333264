import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import check from '../assets/img/check.svg';
import blueCheck from '../assets/img/blueCheck.svg';
import PaymentProfileCard from './PaymentProfileCard';

function CheckIcon({ index, selectedId, id }) {
  if (selectedId === id) {
    return (
      <img className="me-2" src={check} alt="check" style={{ width: 16 }} />
    );
  } else if (index === 1) {
    return (
      <img className="me-2" src={blueCheck} alt="check" style={{ width: 16 }} />
    );
  } else {
    return (
      <img className="me-2" src={check} alt="check" style={{ width: 16 }} />
    );
  }
}

const list = [
  {
    id: 1,
    title: 'Panggilan masuk ',
    price: '10 000 000',
    items: ['Hotline', 'Operator khusus', 'Dukungan teknis', 'Kantor virtual'],
  },
  {
    id: 2,
    title: 'Panggilan keluar',
    price: '20 000 000',
    items: [
      'Memperbarui basis',
      'Akuisisi klien',
      'Survei melalui telepon',
      'Generasi Pemimpin',
    ],
  },
  {
    id: 3,
    title: 'Solusi industri',
    price: '30 000 000',
    items: [
      'Belanja di dalam toko',
      'Perbankan',
      'Dealer mobil',
      'Real Estat',
      'Konsultasi Hukum',
    ],
  },
];

function Prices({ showSelectedPrice, methods, isMethodListLoading }) {
  const [selectedPrice, setPrice] = useState({});
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const priceId = window.localStorage.getItem('price_number');

    if (priceId) {
      const findPrice = list.find((i) => i.id == priceId);
      setPrice(findPrice || {});
    }
  }, []);

  const selectPrice = (price) => {
    setPrice(price);
    window.localStorage.setItem('price_number', price.id);
  };

  return (
    <div className="bg-blue py-3">
      <section className="container py-5">
        <h2 className="text-center mb-5">Pilih paket</h2>
        <div className="row flex-space-between">
          {list.map((i, index) => {
            return (
              <div
                key={i.id}
                className={
                  selectedPrice?.id === i.id
                    ? 'ps-5 pt-5 mx-2 my-2 bg-light card border-0 selected-price'
                    : 'ps-5 pt-5 mx-2 my-2 bg-light card border-0'
                }
                style={{ maxWidth: 380, height: 555 }}
              >
                <h3 className="">{i.title}</h3>
                <div className="flex flex-baseline">
                  <span className="price me-2">{i.price}</span>
                  <span>Rp</span>
                </div>
                <div>
                  {i.items.map((item) => (
                    <div key={item} className="my-3">
                      <>
                        <CheckIcon
                          index={index}
                          selectedId={selectedPrice.id}
                          id={i.id}
                        />
                        {item}
                      </>
                    </div>
                  ))}
                </div>
                <Button
                  onClick={() => {
                    if (showSelectedPrice) {
                      selectPrice(i);
                    }
                  }}
                  className={
                    index === 1
                      ? 'mx-auto price-btn'
                      : 'mx-auto price-btn price-btn-reverse'
                  }
                >
                  Pilih
                </Button>
              </div>
            );
          })}
        </div>

        {showSelectedPrice && selectedPrice.id && (
          <div className="bg-white card border-0 p-5 my-5">
            <h2 className="mb-5">Paket yang dipilih</h2>
            <hr />
            <h3 className="mt-3" style={{ color: '#858C97', fontWeight: 500 }}>
              {selectedPrice.title}
            </h3>
            <div className="flex flex-space-between">
              <div className="flex flex-baseline">
                <span className="price me-2">{selectedPrice.price}</span>
                <span>Rp</span>
              </div>
              <Button onClick={() => setOpenModal(true)}>Isi ulang</Button>
            </div>
          </div>
        )}
      </section>
      {openModal && (
        <PaymentProfileCard
          open={openModal}
          setOpen={setOpenModal}
          selectedPrice={selectedPrice.price}
          methods={methods}
          isMethodListLoading={isMethodListLoading}
        />
      )}
    </div>
  );
}

export default Prices;
