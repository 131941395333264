import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.svg';
import Terms from '../pages/Terms-of-use.pdf';
import PP from '../pages/Privacy-Policy.pdf';

function Footer() {
  return (
    <footer className="bg-white px-3 py-3">
      <div className="container footer">
        <Link to="/" className="flex-center logo">
          <img src={logo} alt="IIMPRO-CC" style={{ width: 55 }} />
          <span className="ms-3">IIMPRO-CC</span>
        </Link>
        <div className="flex mt-4">
          <div>
            PT ZORRAINDO NUSANTARA JAYA Registration No.
            <br />
            AHU-0067545.AH.01.01.
          </div>
          <a href={Terms} target="_blank" rel="noreferrer" className="ms-5">
            Terms of use
          </a>
          <a href={PP} target="_blank" rel="noreferrer" className="ms-5">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
