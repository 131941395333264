import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import { API_PHP_URL } from '../const';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const initialForm = {
  name: '',
  email: '',
  phone: '',
  amount: '',
};

function PaymentProfileCard({
  open,
  setOpen,
  selectedPrice,
  isMethodListLoading,
  methods,
}) {
  const [isLoading, setLoading] = useState(false);
  const [formError, showError] = useState('');

  const [form, setForm] = useState(initialForm);
  const { name, email, phone, amount } = form;

  const [selectedMethod, selectMethod] = useState('');

  useEffect(() => {
    const email = window.localStorage.getItem('username');
    handleChange({ amount: selectedPrice.replace(/ /g, ''), email });
  }, []);

  const handleChange = (object) => {
    setForm({
      ...form,
      ...object,
    });
  };

  const pay = () => {
    setLoading(true);
    axios({
      method: 'post',
      baseURL: API_PHP_URL,
      headers: {
        'content-type': 'application/json',
      },
      params: { action: 'payment' },
      data: {
        customer_name: name,
        customer_email: email,
        customer_phone: phone,
        code: selectedMethod,
        amount: amount.replace(/ /g, ''),
        _token: 'dogcock',
      },
    })
      .then((res) => {
        window.location = res?.data?.redirect_url;
      })
      .catch((e) => {
        showError('Kesalahan');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePay = () => {
    showError('');

    const emailRegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validEmail = emailRegExp.test(email);

    if (!selectedMethod) {
      return showError('Pilih cara pembayaran');
    } else if (!name || !email || !phone || !amount) {
      return showError('Isi formulir');
    } else if (!validEmail) {
      return showError('Salah alamat email');
    }

    pay();
  };

  const handleClose = () => {
    showError('');
    selectMethod('');
    setForm(initialForm);
    setOpen(false);
  };

  return (
    <Modal size="lg" show={open} onHide={handleClose}>
      <Modal.Header className="ui-modal pt-0 pb-3" closeButton>
        <h3 className="mb-0">Isi ulang</h3>
      </Modal.Header>
      <Modal.Body className="py-1">
        <form>
          <p>Pilih cara pembayaran</p>
          <div className="row mb-5">
            {isMethodListLoading ? (
              <Spinner className="mx-auto" variant="info" animation="grow" />
            ) : (
              methods.map((i) => (
                <OverlayTrigger
                  key={i.code}
                  overlay={<Tooltip id="tooltip-disabled">{i.name}</Tooltip>}
                >
                  <div
                    className={`method-card ${
                      selectedMethod === i.code ? 'method-card--selected' : ''
                    } mx-2 mb-4`}
                    onClick={() => selectMethod(i.code)}
                  >
                    <img src={i.image_url} alt={i.name} loading="lazy" />
                  </div>
                </OverlayTrigger>
              ))
            )}
          </div>
          <p>Masukkan detail Anda</p>
          <Form.Label className="ms-2">Name</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Name"
              placeholder="Name"
              value={name}
              required
              type="text"
              name="name"
              onChange={(e) => handleChange({ name: e.target.value })}
            />
          </InputGroup>

          <Form.Label className="ms-2">Email address</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Email adress"
              placeholder="Email"
              value={email}
              required
              onlyRead
              type="email"
              name="email"
            />
          </InputGroup>

          <Form.Label className="ms-2">Phone</Form.Label>
          <InputGroup className="mb-3">
            <PhoneInput
              countryCodeEditable={false}
              placeholder="Enter phone number"
              onlyCountries={['id']}
              country="id"
              value={phone}
              onChange={(value) => handleChange({ phone: value })}
            />
          </InputGroup>

          <Form.Label className="ms-2">Amount (Rp)</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Amount"
              placeholder="Amount (Rp)"
              value={amount}
              required
              type="text"
              name="amount"
              onChange={(e) => handleChange({ amount: e.target.value })}
            />
          </InputGroup>
          <div style={{ height: 100 }}>
            {formError && <Alert variant="danger">{formError}</Alert>}
          </div>
          <div className="text-center">
            <Button
              className="mb-1"
              onClick={handlePay}
              disabled={isLoading}
              variant="primary"
              style={{ width: '100%' }}
            >
              Melanjutkan
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default PaymentProfileCard;
