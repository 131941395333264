const list1 = [
  { icon: require('../assets/img/1.svg').default, title: 'Hotline' },

  { icon: require('../assets/img/2.svg').default, title: 'Dukungan teknis' },

  {
    icon: require('../assets/img/3.svg').default,
    title: 'Memperbarui basis data',
  },

  {
    icon: require('../assets/img/4.svg').default,
    title: 'Survei melalui telepon',
  },

  {
    icon: require('../assets/img/5.svg').default,
    title: 'Belanja di dalam toko',
  },

  { icon: require('../assets/img/6.svg').default, title: 'Dealer mobil' },

  { icon: require('../assets/img/7.svg').default, title: 'Konsultasi Hukum' },
];
const list2 = [
  { icon: require('../assets/img/2-1.svg').default, title: 'Operator khusus' },

  { icon: require('../assets/img/2-2.svg').default, title: 'Kantor virtual' },

  { icon: require('../assets/img/2-3.svg').default, title: 'Akuisisi klien' },

  {
    icon: require('../assets/img/2-4.svg').default,
    title: 'Generasi Pemimpin',
  },

  { icon: require('../assets/img/2-5.svg').default, title: 'Perbankan' },

  { icon: require('../assets/img/2-6.svg').default, title: 'Real Estat' },
];

function Offer() {
  return (
    <div className="bg-white py-5">
      <section className="container p-5">
        <div className="card bg-blue border-0 p-5 mb-5">
          Dengan perkembangan teknologi informasi, semakin banyak orang yang
          berjuang untuk mendapatkan kenyamanan maksimal, yang hanya dapat
          dicapai dengan menggunakan teknologi tinggi. Di masa lalu, untuk
          mendapatkan layanan apa pun, Anda harus pergi ke berbagai otoritas
          untuk mendapatkan layanan apa pun, tetapi hari ini semuanya dapat
          dilakukan secara online.
        </div>
        <h2 className="text-center">Kami menawarkan</h2>

        <div className="row">
          <div className="col-6">
            {list1.map(({ icon, title }) => (
              <div key={title} className="ui-card p-4">
                <img src={icon} alt="logo" style={{ width: 55 }} />
                <span>{title}</span>
              </div>
            ))}
          </div>
          <div className="col-6">
            {list2.map(({ icon, title }) => (
              <div key={title} className="ui-card p-4">
                <img src={icon} alt="logo" style={{ width: 55 }} />
                <span>{title}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Offer;
