import { Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Landing from './pages/Landing';
import Home from './pages/Home';
import './App.scss';

const ROUTES = [
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: '/profile',
    element: <Home />,
  },
];

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          {ROUTES.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
