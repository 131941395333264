import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Envelop from '../assets/img/envelop.svg';
import Lock from '../assets/img/lock.svg';
import axios from 'axios';
import { API_NODE_URL } from '../const';

const initialForm = {
  name: '',
  password: '',
  repeatPassword: '',
};

const errors = [
  { id: 1, message: 'Kesalahan pendaftaran' },
  { id: 2, message: 'Pengguna sudah ada' },
  { id: 3, message: 'Kata sandi tidak valid' },
  { id: 4, message: 'Kesalahan otorisasi' },
  { id: 5, message: 'Kesalahan mendapatkan daftar' },
  { id: 6, message: 'Pengguna tidak diotorisasi' },
  { id: 7, message: 'Akses ditolak' },
  { id: 8, message: 'Pengguna tidak ada' },
];

function ModalComponent({ open, setOpen }) {
  const [isLoading, setLoading] = useState(false);
  const [formError, showError] = useState('');
  const [needShowSuccess, showSuccess] = useState(false);

  const [form, setForm] = useState(initialForm);
  const { name, password, repeatPassword } = form;

  const handleChange = (object) => {
    const isRepeatPassword = Object.keys(object)[0] === 'repeatPassword';
    const isPassword = Object.keys(object)[0] === 'password';

    if (isRepeatPassword && password !== object.repeatPassword) {
      showError('Kata sandi tidak cocok');
    } else if (isPassword && repeatPassword !== object.password) {
      showError('Kata sandi tidak cocok');
    } else {
      showError('');
    }

    setForm({
      ...form,
      ...object,
    });
  };

  const handleClose = () => {
    showError('');
    showSuccess(false);
    setForm(initialForm);
    setOpen(false);
  };

  const getErrors = (e) => {
    showSuccess(false);

    const serverError = e?.response?.data;

    if (serverError?.code === 1 && serverError?.errors?.length > 0) {
      // TODO
      return showError(serverError.erros.map((i) => i.msg));
    }
    const message = errors.find((e) => e.id === serverError?.code)?.message;

    if (!message) {
      return showError('Kesalahan');
    }
    return showError(message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validEmail = emailRegExp.test(name);

    if (
      validEmail &&
      password &&
      repeatPassword &&
      password === repeatPassword
    ) {
      setLoading(true);
      axios({
        method: 'post',
        baseURL: API_NODE_URL,
        url: '/auth/registration',
        headers: { 'content-type': 'application/json' },
        data: { username: name, password },
      })
        .then(() => {
          showSuccess(true);
          showError('');
        })
        .catch((e) => {
          getErrors(e);
        })
        .finally(() => setLoading(false));
    } else if (!validEmail) {
      showError('Salah alamat email');
    } else {
      showError('Kesalahan');
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header className="ui-modal pt-0 pb-5" closeButton>
        <h3 className="mb-0">Pendaftaran akun</h3>
      </Modal.Header>
      <Modal.Body className="py-1">
        <form onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              <img src={Envelop} alt="envelop" />
            </InputGroup.Text>
            <Form.Control
              aria-label="Email adress"
              aria-describedby="basic-addon1"
              placeholder="Email adress"
              value={name}
              required
              type="email"
              name="name"
              onChange={(e) => handleChange({ name: e.target.value })}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              <img src={Lock} alt="lock" />
            </InputGroup.Text>
            <Form.Control
              aria-label="Email adress"
              aria-describedby="basic-addon1"
              placeholder="Kata sandi"
              value={password}
              required
              type="password"
              name="password"
              onChange={(e) => handleChange({ password: e.target.value })}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              <img src={Lock} alt="lock" />
            </InputGroup.Text>
            <Form.Control
              aria-label="Email adress"
              aria-describedby="basic-addon1"
              placeholder="Kata sandi lagi"
              value={repeatPassword}
              required
              type="password"
              name="repeatPassword"
              onChange={(e) => handleChange({ repeatPassword: e.target.value })}
            />
          </InputGroup>

          <div style={{ height: 100 }}>
            {formError && <Alert variant="danger">{formError}</Alert>}

            {needShowSuccess && (
              <Alert variant="success">
                Pendaftaran berhasil. Anda dapat login
              </Alert>
            )}
          </div>

          <div className="text-center">
            <Button
              className="mb-1"
              type="submit"
              disabled={isLoading}
              variant="primary"
              style={{ width: '100%' }}
            >
              Registrasi
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalComponent;
