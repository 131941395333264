import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Badge from 'react-bootstrap/Badge';
import UserPic from '../assets/img/user-pic.png';
import Prices from './Prices';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { API_NODE_URL, API_PHP_URL } from '../const';

const rupiahFormatter = require('rupiah-formatter');

const titleStyle = {
  fontSize: 32,
  fontWeight: 800,
  color: '#000000',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
};

const subTitleStyle = {
  fontSize: 24,
  fontWeight: 800,
  color: '#000000',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
};

function Home() {
  const [user, setUser] = useState('');
  const [balance, setBalance] = useState('');
  const [payments, setHistory] = useState([]);

  const [methods, setMethods] = useState([]);
  const [isMethodListLoading, setMethodListLoading] = useState(true);

  const [isLoading, setLoading] = useState();
  const navigate = useNavigate();

  const getImg = (paymentCode) => {
    return methods?.find((i) => i.code === paymentCode)?.image_url;
  };
  const getTooltip = (paymentCode) => {
    return methods?.find((i) => i.code === paymentCode)?.name;
  };
  const formatDate = (timestamp) => {
    if (!timestamp) {
      return '';
    }

    const time = timestamp * 1000;
    const dateFormat = new Date(time);

    return (
      'Date ' +
      dateFormat.getDate() +
      '/' +
      (dateFormat.getMonth() + 1) +
      '/' +
      dateFormat.getFullYear() +
      ' ' +
      dateFormat.getHours() +
      ':' +
      dateFormat.getMinutes() +
      ':' +
      dateFormat.getSeconds()
    );
  };

  const getMethods = () => {
    setMethodListLoading(true);
    axios({
      method: 'get',
      baseURL: API_PHP_URL,
      headers: {
        'content-type': 'application/json',
      },
      params: { action: 'list' },
    })
      .then((res) => {
        setMethods(res?.data);
      })
      .catch((e) => {})
      .finally(() => {
        setMethodListLoading(false);
      });
  };

  const removeUserData = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('username');
    setUser('');
    navigate('/');
  };

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    const email = window.localStorage.getItem('username');
    setUser(email);
    getMethods();

    axios({
      method: 'get',
      baseURL: API_NODE_URL,
      url: '/auth/me',
      headers: {
        'content-type': 'application/json',
        Authorization: `Basic ${token}`,
      },
    })
      .then((res) => {
        if (res?.data.status !== 'success') {
          removeUserData();
        } else {
          setBalance(res?.data?.balance);
        }
      })
      .catch((e) => {
        removeUserData();
      })
      .finally(() => {});

    setLoading(true);
    axios({
      method: 'get',
      baseURL: API_NODE_URL,
      url: '/auth/history',
      headers: {
        'content-type': 'application/json',
        Authorization: `Basic ${token}`,
      },
    })
      .then((res) => {
        if (res?.data.status !== 'success') {
          removeUserData();
        } else {
          setHistory(res?.data?.payments);
        }
      })
      .catch((e) => {
        removeUserData();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="bg-blue pt-5">
        <section className="container py-5">
          <div className="flex-center mb-4">
            <div className="user-avatar me-2">
              <img src={UserPic} style={{ width: 40 }} alt="user-avatar" />
            </div>
            <div>
              <span style={titleStyle}>{user}</span>
              {balance && (
                <div style={subTitleStyle}>
                  {rupiahFormatter.formatToString(balance, 'Rp ')}
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="mt-5 mb-4" style={titleStyle}>
              Cerita
            </div>
            {isLoading
              ? 'Loading...'
              : payments.map((payment) => {
                  const { _id, amount, code, state, date } = payment;
                  return (
                    <div key={_id} className="flex-center mb-4">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {getTooltip(code)}
                          </Tooltip>
                        }
                      >
                        <div className="profile-method-card">
                          <img src={getImg(code)} loading="lazy" />
                        </div>
                      </OverlayTrigger>

                      <div className="mx-3">
                        {date && formatDate(date)}

                        <div style={subTitleStyle}>
                          {rupiahFormatter.formatToString(amount, 'Rp ')}
                        </div>
                      </div>

                      {state === 'PENDING' && <Badge bg="info">{state.toLowerCase()}</Badge>}
                      {state === 'SUCCESS' && <Badge bg="success">{state.toLowerCase()}</Badge>}
                    </div>
                  );
                })}
          </div>
        </section>
      </div>

      <Prices
        showSelectedPrice={true}
        methods={methods}
        isMethodListLoading={isMethodListLoading}
      />
    </>
  );
}

export default Home;
